import React, { Component } from 'react';
import './Inicio.scss'
import SliderMain from '../../component/slider/sliderMain'
import OwlCarousel from "react-owl-carousel2"
import MediaQuery from 'react-responsive';
import Popup from '../../component/popup/popup'
import Contacto from '../../component/contacto/contacto';
import sr from '../../component/scrollreveal/scroll'
import $ from 'jquery'
import Whatsapp from '../../component/whatsapp/whatsapp';
import { BaseValidatonSchema, HomeValidatonSchema } from '../../component/common/forms/constraints/ValidatonSchemas';
import { checkableBoolProps, FormContainer, setInputProps} from '../../component/common/forms/Form';
import { MailService } from '../../component/common/services/MailService';
import { InterestedEmail } from '../../component/common/Mails/InterestedEmail';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import Data from '../../data/data';
import Proyectos from '../../component/proyectos/proyecto';
import Slider from 'react-slick';
import dataInicio from '../../data/inicio';


const settings ={
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    pauseOnHover:false,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    
};
export default class Inicio extends Component {
    constructor(props){
        super(props)
        this.mailService = new MailService()
        this.submitForm = this.submitForm.bind(this)
        this.onDragged = this.onDragged.bind(this)
        this.btnLeftSlider = this.btnLeftSlider.bind(this)
        this.btnRightSlider = this.btnRightSlider.bind(this)
        this.updateContext = this.updateContext.bind(this)
        this.moveTo = this.moveTo.bind(this)
        this.state = {
            events:{onDragged:this.onDragged,onChanged:function(item){}},
            context:"",
            index:0,
            options:{
                rewind: true,
                loop:false,
                nav:false,
                center:false,
                autoWidth:false,
                speed:1000,
                smartSpeed:300,
                dots:false,
                items:1,
                startPosition:0,
                rewind:false,
                slideBy:1,
            }
        }
        this.sliderCarousel = React.createRef();
    }
    componentDidMount(){
        console.log(this.props.data.proyectos)
        function showUp(el){
            el.classList.add("show")
        }
        function scaleLine(el){
            el.classList.add("scale")
        }
        sr.reveal(".title-part",{opacity:0, beforeReveal: showUp})
        sr.reveal(".line-section",{opacity:0, beforeReveal: scaleLine})
    }
    updateContext(context){
        // this.setState({context:context})
    }
    showModal = ()=>{}
    submitForm(context){
        this.showModal = context.showModal
        return (values, {setSubmitting, resetForm})=> {
            const project = this.props.data.proyectos.find(p=>p.title === values.project)

            // if (project.crm.from_mail_wsp){
            //     sessionStorage.setItem("seller_phone", project.crm.from_mail_wsp[Math.floor(Math.random() * project.crm.from_mail_wsp.length)])
            // }

            // const [fromEmail, seller_phone] = sessionStorage.getItem("seller_phone").split(",")
            const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })
            
            new URLSearchParams(localStorage.getItem('url_query'))
                .forEach((v, k) => /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v))

            const axios = require("axios")
            axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
                "ss_id": "14REPqa0jWd-zIjm6mubfty4spw2N7143ufvg0zV_lAY",
                "range": "Mirano Web!A:XX",
                "values": [[date, values.fname, values.lname, values.telephone, values.email, values.project == "PRÓXIMAMENTE" ? "Litoral 900" : values.project, "", "",
                values.utm_source, values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term]]
            })

            // this.mailService.from(`Web <${fromEmail}>`)
            //     .to(project.evoltaEmail)
            //     .subject("Evolta lead")
            //     .html(InterestedEmail(values))

            

            let promise
            // if(project.crm.id === 1536){
                promise = axios.post("https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0", {
                    "environment": "mirano",
                    "lead": {
                        "IdProyecto": project.crm.id,
                        "Nombres": values.fname,
                        "Apellidos": values.lname,
                        "Correo": values.email,
                        "Celular": values.telephone,
                        "Comentario": values.observation,
                        ...project.crm.id !== 1536 && {"IdTipoPortal": 10},
                        "url_query": localStorage.getItem('url_query')
                    }
                })
            // } else {
            //     promise = this.mailService.send()
            // }
            
            promise.then(r=>{
                const find_project = dataInicio.proyectos.find(p=>p.title === values.project)
                this.showModal(values.project,find_project.distrito)
                window.dataLayer && window.dataLayer.push({
                    'event': 'LeadCreated',
                    'source': 'Form',
                    'project': values.project,
                })
                resetForm()
                document.querySelector("#form-home").reset()
                project.crm.from_mail_wsp && window.setGeneralSellerPhones()
            })
            .catch(e=>{
                console.error(e)
                Swal.fire({
                    title: '',
                    text: '¡Error! Intentelo más tarde',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: 'red'
                })
            })
            .finally(() => setSubmitting(false))
        }
    }

    onDragged = (e)=>{
        console.log("move",e.item.index)
        $(".content-text-all .title-card").text(this.props.data.proyectos[e.item.index].title)
        $(".content-text-all .sub-title").text(this.props.data.proyectos[e.item.index].distrito)
        
        $(".content-balls .item").removeClass("active")
        document.querySelector(`.item-ball-${e.item.index}`).classList.add("active")
    }
    btnLeftSlider = () => {

        let index = this.sliderCarousel.currentPosition
        console.log("slide:",index)
        try{
            $(".content-text-all .title-card").text(this.props.data.proyectos[index-1].title)
            $(".content-text-all .sub-title").text(this.props.data.proyectos[index-1].distrito)
        }catch(err){
            console.error(err)
        }
        if(document.querySelector(`.item-ball-${index-1}`)){
            $(".content-balls .item").removeClass("active")
            document.querySelector(`.item-ball-${index-1}`).classList.add("active")
        }
        
    }
    btnRightSlider = (e) => {
        let index = this.sliderCarousel.currentPosition
        console.log("slide:",index)
        try{
            $(".content-text-all .title-card").text(this.props.data.proyectos[index+1].title)
            $(".content-text-all .sub-title").text(this.props.data.proyectos[index+1].distrito)
        }catch(err){
            console.error(err)
        }
        
        if(document.querySelector(`.item-ball-${index+1}`)){
            $(".content-balls .item").removeClass("active")
            document.querySelector(`.item-ball-${index+1}`).classList.add("active")
        }
    }
    moveTo(from,to){
        document.querySelector("#selec-proyectos").value = to
        $('html, body').animate({
            scrollTop: $(".content-formulario-all").offset().top
        }, 700);
        
    }
    initialValues = {
        fname: null,
        lname: null,
        email: null,
        telephone: null,
        project: null,
        terms: false,
    }
    render() {
        return (
        <Data.Consumer>
            {context=>(
                <main className="inicio">
                    <Helmet>
                        <title>{"Mirano Inmobiliaria | Venta de Departamentos en Lima"}</title>
                        <meta  name="description" content="En Mirano Inmobiliaria mantenemos el equilibrio entre la modernidad y exclusividad. Encuentra el departamento perfecto en Barranco, Chacarilla, Miraflores y Lince."></meta>
                        <meta  name="keywords" content="inmobiliaria, inmobiliaria en peru, inmobiliaria en lima, inmobiliaria peruana, mirano, mirano inmobiliaria, proyecto inmobiliario, departamentos en venta, venta de departamentos, departamentos en venta en lima, venta de departamentos en lima, departamentos en lima, departamentos en venta en barranco, venta de departamentos en barranco, departamentos en barranco, departamentos en venta en miraflores, venta de departamentos en miraflores, departamentos en miraflores, departamentos en venta en chacarilla, venta de departamentos en chacarilla, departamentos en chacarilla, departamentos en venta en lince, venta de departamentos en lince, departamentos en lince, departamentos en construcción, departamentos en entrega inmediata, departamentos en preventa, proyecto en construcción, proyecto en entrega inmediata, proyecto en preventa"></meta>
                    </Helmet>
                    {/* <Popup/> */}
                    <Whatsapp projects={this.props.data.proyectos}></Whatsapp>
                    <SliderMain data={this.props.data.slider}></SliderMain>
                    <section className="section-inicio">
                                <h2 className="title c-primary grandtitle-fondo title-block">CAMBIAMOS M² POR METROS PENSADOS</h2>
                        {/* <div className='content-title-main'>
                            <div className="box">
                                <div>
                                <h2 className="title c-primary grandtitle-fondo title-none">CAMBIAMOS M² POR METROS PENSADOS</h2>
                                </div>
                                <div className='title-part title-fondo title-part-1 c-primary'>
                                <span  className="span-fondo-title-one">CONECTAR ES TRANSFORMAR </span>
                                <div className="img-fondo">
                                    <div class="img-item-1">
                                        <img class="img-widht" src={require('../../assets/images/inicio/fondo/chica-y-perro.png')}></img>
                                    </div>
                                    <div class="img-item-2">
                                        <img class="img-widht" src={require('../../assets/images/inicio/fondo/familia.png')}></img>
                                    </div>
                                    <div class="img-item-3">
                                        <img  class="img-widht" src={require('../../assets/images/inicio/fondo/vivienda.png')}></img>
                                    </div>
                                </div>
                                </div>
                                <div className='line line-section'></div>
                                <div className='title-part title-part-italic title-part-2 c-primary'><p className="paragraph-mirano">Mirano   <br/><span className="span-fondo-title-two">arquitectura</span>  <br/><span className="span-fondo-title-two">que conecta</span>  <br/>Inmobiliaria</p><div className='text text-2 c-white'>Un proyecto no es solo un edificio,  <br/>Un proyecto es un todo.  <br/>Una suma de partes elegidas con cuidado.  <br/>Con una idea que lo sustenta.  <br/>Para ser flexible y funcional.  <br/>Para crecer de adentro hacia afuera.  <br/>Para transformar.</div></div>
                            </div>
                        </div> */}
                    </section>
                    <Proyectos data={this.props.data}></Proyectos>
                    <section className="section proyectos-entregados">
                        <h2 className="title c-white title-horizontal">ENTREGADOS</h2>
                        <div className='entregados'>
                            <span className='title-venta'>ENTREGADOS</span>
                            <div className='line-venta'><div className='barra'></div></div>
                        </div>
                        <div className="content-title">
                            <h2 className="title-section c-white">
                                BACKGROUND
                            </h2>
                        </div>
                        <div className="content-cards container">
                            {
                                this.props.data.entregados.map((item,index)=>{
                                    return(
                                    <div className="card">
                                        <div className={`content-img ${"content-img-"+index}`}>
                                            {
                                                (item?.slider?.length>0) ? <Slider {...settings}>
                                                    {
                                                        item.slider.map((slide,index)=>{
                                                            return(
                                                                <div className="item">
                                                                    <img src={slide.img} loading='lazy'></img>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Slider>
                                                : <img src={item.img} loading='lazy'></img>
                                            }
                                        </div>
                                        <div className="content-text">
                                            <div className="info">
                                                <span className="title-info c-white">{item.title}</span>
                                                <span className="distric c-white">{item.distric}</span>
                                            </div>
                                            <div className="entrega c-white">100% ENTREGADO</div>
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </section>
                    <section className='contact' id="contact">
                        <div className="content-formulario-all">
                            <div className="formulario-inicio container">
                                <div className="content-form">
                                    <div className="title-formulario">
                                        Conecta con nuestro proyecto
                                    </div>
                                    <FormContainer
                                        initialValues={this.initialValues}
                                        validationSchema={HomeValidatonSchema}
                                        onSubmit={this.submitForm(context)}>
                                        {form => {const {handleSubmit, errors, touched, isSubmitting} = form;
                                        return(
                                        <form id="form-home" className="form" onSubmit={handleSubmit}>
                                            <input {...setInputProps("fname", "input", form)} type="text" placeholder="NOMBRE*"></input>
                                            <input {...setInputProps("lname", "input", form)} type="text" placeholder="APELLIDO*"></input>
                                            <input {...setInputProps("telephone", "input", form)} type="tel" placeholder="TELÉFONO*"></input>
                                            <input {...setInputProps("email", "input", form)} type="email" placeholder="E-MAIL*"></input>
                                            <select {...setInputProps("project", "input", form)} type="text" id="selec-proyectos">
                                                <div className='icon'>
                                                </div>
                                                <option value="" disabled selected>PROYECTO DE INTERÉS</option>
                                                {this.props.data.proyectos.map((item, index)=>
                                                    <option value={item.title}>{item.title} - {item.distrito}</option>
                                                )}
                                            </select>
                                            <MediaQuery query="(min-width: 800px)">
                                                <button type="submit" className="btn-submit" disabled={isSubmitting}>{isSubmitting ? 'ENVIANDO' : 'ENVIAR'}</button>
                                            </MediaQuery>
                                            <div className="content-input terms">
                                                <input id="term" {...checkableBoolProps("terms", "checkbox", form)} type="checkbox">
                                                </input>
                                                <label className={"text-term"} htmlFor="term">He leído y acepto la <strong><a target="_blank" href="/Mirano-Politicas-de-privacidad.pdf">política de datos personales</a></strong></label>
                                            </div>
                                            <MediaQuery query="(max-width: 799px)">
                                                <button type="submit" className="btn-submit" disabled={isSubmitting}>{isSubmitting ? 'ENVIANDO' : 'ENVIAR'}</button>
                                            </MediaQuery>
                                        </form>
                                        )}}
                                    </FormContainer>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </section>
                </main>
            )}
        </Data.Consumer>
        );
    }
}
