import React, { Component,Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Footer from './component/footer/footer'
import Navbar from './component/navbar/navbar'
import dataInicio from './data/inicio'
import Data, { PROJECTS} from './data/data'
import $ from 'jquery'
import Form from './pages/contacto/contacto'
import Whatsapp from './component/whatsapp/whatsapp'
import ScrollToTop from './component/ScrollToTop'
import Proyectos from './component/proyectos/proyecto'
import Inicio from './pages/inicio/Inicio'
import Nosotros from './pages/nosotros/nosotros'
import Message from './component/message/message'
import Loader from './component/loader/loader'
import Contacto from './component/contacto/contacto'
import Promos from './pages/promos/promos'
import Modal from './component/modal/modal'
import Consumidor from './component/consumidor/consumidor'
import Proteccion from './component/consumidor/Proteccion'
import { Proximamente } from './pages/proximamente/proximamente'
import { Gracias } from './pages/gracias/gracias'
import { projects_data } from './data/projects'
import { Working } from './pages/Working/Working'
import { Helmet } from 'react-helmet'
import { ProctecionAlConsumidor } from './pages/proctecion-al-consumidor/proctecion-al-consumidor'
// import { LibroReclamaciones } from './pages/reclamaciones/reclamaciones'
import {Reclamacion} from './pages/reclamacion/reclamacion'
import { TextoLegales } from './pages/textos-legales/textolegales'
const Template = React.lazy(() => import(/* webpackChunkName: "politicas"*//* webpackPreload: true */'./pages/template/template'))
const Politicasdeprivacidad = React.lazy(() => import(/* webpackChunkName: "politicas"*//* webpackPreload: true */'./pages/politicasdeprivacidad/politicasdeprivacidad'));

export default class Routes extends Component {
    constructor(props){
        super(props)
        this.showModal = this.showModal.bind(this)
        this.change = this.change.bind(this)
        this.state = {
            message: false,
            phone: "",
            project: "",
        }
    }
    showModal(project,distrito){
        this.setState({
            message: true,
            project: project,
            phone: sessionStorage.getItem("seller_phone").split(",")[1],
            distrito: distrito
        })
    }
    change(e,value){
        this.setState({message:false})
    }
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Data.Provider value={{data: this.state, showModal: this.showModal, change: this.change}}>
                        {
                            process.env.NODE_ENV == "development" ? null : <Loader/> 
                        }
                        
                        <Navbar></Navbar>
                        <Message></Message>
                        <Modal>
                            <Consumidor/>
                        </Modal>
                        <Switch>
                            <Route exact path="/">
                                <Inicio data={dataInicio}></Inicio>
                            </Route>
                            
                            {/* <Route exact path="/Chacarilla/Monte-Umbroso-122">
                                <Suspense fallback={<div></div>}>
                                    <Helmet>
                                        <title>{"Monte Umbroso 122 | Departamentos en Chacarilla"}</title>
                                        <meta  name="description" content="Departamentos en Chacarilla de 3 dormitorios de 116 m² y 119 m². Proyecto en construcción. Vive frente a más de 10,000 m2 de parque, cerca a colegios, restaurantes, supermercados y más. Ingresa y conoce más de tu nuevo departamento."></meta>
                                        <meta  name="keywords" content=" inmobiliaria, inmobiliaria en peru, inmobiliaria en lima, inmobiliaria peruana, mirano, mirano inmobiliaria, proyecto inmobiliario, departamentos en venta, venta de departamentos, departamentos en venta en lima, venta de departamentos en lima, departamentos en lima, departamentos en venta en chacarilla, venta de departamentos en chacarilla, departamentos en chacarilla, departamentos frente a parque, proyecto en construccion, departamentos en construccion"></meta>
                                    </Helmet>
                                    <Template data={projects_data.get(PROJECTS.mu122)}></Template>
                                </Suspense>
                            </Route> */}
                            
                            <Route exact path="/Lince/Pasteur-1228">
                                <Suspense fallback={<div></div>}>
                                    <Template data={projects_data.get(PROJECTS.p1228)}></Template>
                                </Suspense>
                            </Route>
                            <Route exact path="/textos-legales">
                                <TextoLegales/>
                            </Route>
                                <Route exact path="/Lince/Pasteur-1250">
                                <Suspense fallback={<div></div>}>
                                    <Template data={projects_data.get(PROJECTS.p1250)}></Template>
                                </Suspense>
                                </Route>
                            <Route exact path="/Lince/Villavicencio-1320">
                                <Suspense fallback={<div></div>}>
                                    <Template data={projects_data.get(PROJECTS.mu122)}></Template>
                                </Suspense>
                            </Route>
                            <Route exact path="/Miraflores/Tudela-y-Varela-445">
                                <Suspense fallback={<div></div>}>
                                    <Template data={projects_data.get(PROJECTS.p1250)}></Template>
                                </Suspense>
                            </Route>
                            <Route exact path="/contacto">
                                <Form data={dataInicio}></Form>
                            </Route>
                            <Route exact path="/nosotros">
                                <Nosotros></Nosotros>
                            </Route> 
                            <Route exact path="/proyectos">
                                <Whatsapp projects={dataInicio.proyectos} active={true}/>
                                <Helmet>
                                    <title>{"Mirano Inmobiliaria | Proyectos"}</title>
                                    <meta  name="description" content="En Mirano Inmobiliaria mantenemos el equilibrio entre la modernidad y exclusividad. Encuentra el departamento perfecto en Barranco, Chacarilla, Miraflores y Lince."></meta>
                                    <meta  name="keywords" content="inmobiliaria, inmobiliaria en peru, inmobiliaria en lima, inmobiliaria peruana, mirano, mirano inmobiliaria, proyecto inmobiliario, departamentos en venta, venta de departamentos, departamentos en venta en lima, venta de departamentos en lima, departamentos en lima, departamentos en venta en barranco, venta de departamentos en barranco, departamentos en barranco, departamentos en venta en miraflores, venta de departamentos en miraflores, departamentos en miraflores, departamentos en venta en chacarilla, venta de departamentos en chacarilla, departamentos en chacarilla, departamentos en venta en lince, venta de departamentos en lince, departamentos en lince, departamentos en construcción, departamentos en entrega inmediata, departamentos en preventa, proyecto en construcción, proyecto en entrega inmediata, proyecto en preventa"></meta>
                                </Helmet>
                                <Proyectos data={dataInicio} showTitle={true}>
                                    <h2 class="c-primary Display-Thin active text-center mt-4 mb-2">PROYECTOS <span class="Pro-Italic">EXCLUSIVOS</span>  EN VENTA</h2>
                                </Proyectos>
                            </Route> 
                            <Route exact path="/Politicas-de-privacidad">
                                <Suspense fallback={<div></div>}>
                                    <Politicasdeprivacidad></Politicasdeprivacidad>
                                </Suspense>
                            </Route>
                            <Route exact path="/outlet_wow">
                                <Promos projects={dataInicio.proyectos}/>
                            </Route>
                            <Route exact path="/Litoral-900">
                                <Helmet>
                                    <title>{"Litoral 900 | Departamentos en Miraflores"}</title>
                                    <meta  name="description" content="Departamentos en Av. Ejército 890, Miraflores de 1 y 2 dormitorios desde 55 m2. Proyecto en lanzamiento. Vive a sólo una cuadra del Malecón de Miraflores y cerca al nuevo centro gastronómico del distrito. Ingresa y conoce más de tu nuevo departamento."></meta>
                                    <meta  name="keywords" content="inmobiliaria, inmobiliaria en peru, inmobiliaria en lima, inmobiliaria peruana, mirano, mirano inmobiliaria, proyecto inmobiliario, departamentos en venta, venta de departamentos, departamentos en venta en lima, venta de departamentos en lima, departamentos en lima, departamentos en venta en miraflores, venta de departamentos en miraflores, departamentos en miraflores, proyecto en preventa, departamentos en preventa, departamentos frente al malecon de miraflores, departamentos frente al mar"></meta>
                                </Helmet>
                                <Proximamente project={projects_data.get(PROJECTS.l900)}></Proximamente>
                            </Route>
                            <Route exact path="/Litoral-900/gracias">
                                <Gracias/>
                            </Route>
                            <Route exact path="/avance-de-obra">
                                <Working></Working>
                            </Route>
                            <Route exact path="/proteccion-al-consumidor">
                                <ProctecionAlConsumidor></ProctecionAlConsumidor>
                            </Route>
                            <Route exact path="/libro-de-reclamaciones">
                                {/* <LibroReclamaciones></LibroReclamaciones> */}
                                <Reclamacion></Reclamacion>
                            </Route>
                            <Redirect to={"/"}></Redirect>
                        </Switch>
                        <Footer></Footer>
                    </Data.Provider>
                </ScrollToTop>
            </Router>
        )
    }
}