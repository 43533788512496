import { dataArrayLitoral } from "./litoral";
import { dataArrayMU } from "./monteumbroso";
import { dataArraySanMartin } from "./sanmartin";
import { dataArraySucre } from "./sucre";
import { dataArraySunny } from "./sunny";

export const dataProjects = [
	dataArrayLitoral,
	dataArrayMU,
	dataArraySanMartin,
	dataArraySucre,
	dataArraySunny
];
