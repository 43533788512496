import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import Data from '../../data/data'
import './message.scss'

function capitalizeWords(input) {
    if (typeof input !== 'string') {
        return input;
    }
    return input
        .toLowerCase() // Convierte toda la cadena a minúsculas.
        .split(' ') // Divide la cadena en palabras separadas por espacios.
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Convierte la primera letra de cada palabra a mayúscula.
        .join(' '); // Une las palabras nuevamente con un espacio.
}
export default class Message extends Component {
    constructor(props){
        super(props)
    }
    componentDidMount(){

    }
    render(){
        return (
            <Data.Consumer>
                {context  =>{ 
                    console.log('context',context)
                    return (
                        <div className={`message ${context.data.message ? "active" : ""}`} data-modal="modal"  onClick={(e)=>{context.change(e)}}>
                            <div className='contain-message'>
                                <div className='title-message'>
                                    ¡MUCHAS GRACIAS!
                                </div>
                                <div className='content'>
                                    <span className='title-message-2 text-center'>Tus datos ya fueron registrados</span>
                                    <p className="paragraph paragraph-1">estás un paso más cerca de tu  <br/>nuevo departamento.</p>
                                    <p className="paragraph">Puedes comunicarte con un asesor de ventas aquí:</p>
                                    <div className='buttons'>
                                    <MediaQuery query="(min-width: 850px)">
                                        <a id="phoneNumber" href={`https://wa.me/51${context.data.phone}?text=Hola, he visitado su web y quisiera más información del proyecto ${context.data.project} en ${capitalizeWords(context.data.distrito)}`} target="_blank" className='button b-green c-white'>
                                            <div className='icon whatsapp'></div>
                                            <span className='text'>WhatsApp</span>
                                        </a>
                                    </MediaQuery>
                                    <MediaQuery query="(max-width: 850px)">
                                        <a id="phoneNumber" href={`https://api.whatsapp.com/send/?phone=51${context.data.phone}&text=Hola, he visitado su web y quisiera más información del proyecto ${context.data.project} en ${capitalizeWords(context.data.distrito)}`} target="_blank" className='button b-green c-white'>
                                            <div className='icon whatsapp'></div>
                                            <span className='text'>WhatsApp</span>
                                        </a>
                                    </MediaQuery>
                                        <a href={`tel:${context.data.phone}`} target="_blank" className='button b-primary c-white'>
                                            <div className='icon phone'></div>
                                            {context.data.phone}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                }
            </Data.Consumer>
        )
    }
}