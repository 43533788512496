export const dataArraySunny = {
	project_name: 'Sunny Park',
	documents: [
		{
			type: 'file',
			name: 'Datos de proveedores',
			file: '#',
		},
		{
			type: 'file',
			name: 'Ficha RUC',
			file: 'https://mirano.pe/documentos/sunny/02.%20FICHA%20RUC/ficha%20ruc%20Marsala.pdf',
		},
		{
			type: 'file',
			name: 'Certificación Edge',
			file: "#", 
				
		},
		{
			type: 'file',
			name: 'Memoria descriptiva del proyecto',
			file: 'https://mirano.pe/documentos/sunny/04.%20MEMORIA%20DESCRIPTIVA%20DEL%20PROYECTO/AP_JrCentenario_MD_Rev3_2024.06.21.docx',
		},
        {
			type: 'dir',
			name: 'Partida registral del proyecto',
			data: [
				{
					name: 'A. CRI Lote 13 La Molina.pdf',
					file: 'https://mirano.pe/documentos/sunny/05.%20PARTIDA%20REGISTRAL%20DEL%20PROYECTO/CRI%20Lote%2013%20La%20Molina.pdf',
				},
				{
					name: 'B. CRI lote 14 La Molina.pdf',
					file: 'https://mirano.pe/documentos/sunny/05.%20PARTIDA%20REGISTRAL%20DEL%20PROYECTO/CRI%20lote%2014%20La%20Molina.pdf',
				},
			],
		},
		{
			type: 'file',
			name: 'Vigencia de poder',
			file: 'https://mirano.pe/documentos/sunny/06.%20VIGENCIA%20DE%20PODER/Vigencia%20Marsala%20RBZ.pdf',
		},
		{
			type: 'file',
			name: 'Licencia de edificación del proyecto',
			file: 'https://mirano.pe/documentos/sunny/07.%20LICENCIA%20DE%20EDIFICACION%20DEL%20PROYECTO/ACTA%20ARQ%201ERA%20REV%20MELCEN%20LA%20MOLINA%20.pdf'
		},
		{
			type: 'file',
			name: 'Relación de acabados',
			file: 'https://mirano.pe/documentos/sucre/10.%20relacion%20de%20acabados/sucre%20296%20acabados%20dpto%20tipico.pdf',
		},
		{
			type: 'file',
			name: 'Condiciones generales sobre el precio de compra',
			file: 'https://mirano.pe/documentos/sunny/09.%20CONDICIONES%20GENERALES%20SOBRE%20EL%20PROCESO%20DE%20VENTA/Consideraciones%20sobre%20el%20proceso%20de%20venta.docx',
		},
		{
			type: 'dir',
			name: 'Modelo de contrato de separación',
			data: [
				{
					name: 'A. FileAcuerdo de Reserva Preferente V11.09.doc',
					file: 'https://mirano.pe/documentos/sunny/10.%20MODELO%20DE%20CONTRATO%20DE%20SEPARACION%20Y%20MINUTA/Acuerdo%20de%20Reserva%20Preferente%20V11.09.doc',
				},
				{
					name: 'B. FileModelo de minuta de compra venta.docx',
					file: 'https://mirano.pe/documentos/sunny/10.%20MODELO%20DE%20CONTRATO%20DE%20SEPARACION%20Y%20MINUTA/Modelo%20de%20minuta%20de%20compra%20venta.docx',
				},
			],
		},
		{
			type: 'file',
			name: 'Recomendaciones generales',
			file: 'https://mirano.pe/documentos/sunny/11.%20RECOMENDACIONES%20GENERALES/Recomendaciones%20generales.docx',
		},
		{
			type: 'file',
			name: 'HR y PU 2024',
			file: '#',
		},
		{
			type: 'file',
			name: 'Comprobante de pago del impuesto predial del 2023',
			file: '#'
		},
		
	],
};
